import './session.scss';

import { Link, useParams } from 'react-router-dom';
import { formatDate, formatTime, formatWeekday } from '../util/dateUtil';
import { SessionList } from './SessionList';
import { Fragment, ReactNode, useEffect, useRef } from 'react';
import { HostLinkBox } from './HostLinkBox';
import { SdgIconList } from './SdgIcon/SdgIconList';
import { SessionMiniMap } from './SessionMiniMap';
import { useSessionContext } from './SessionContext';

function eventUrl(link: string): ReactNode {
    if (link.startsWith('http')) {
        return (
            <a href={link} target="_blank" rel="noreferrer">
                {link}
            </a>
        );
    }

    if (link.startsWith('www.')) {
        return (
            <a href={`http://${link}`} target="_blank" rel="noreferrer">
                {link}
            </a>
        );
    }

    if (link.match(/@/)) {
        return <a href={`mailto:${link}`}>{link}</a>;
    }

    return link;
}

export const Session = () => {
    const ref = useRef<HTMLDivElement>(null);

    const {
        sessions,
        categories: { locationType, targetGroup },
    } = useSessionContext();

    const params = useParams();
    const sessionId = parseInt(params.sessionId ?? '', 10);
    const session = sessions.find((x) => x.id === sessionId);

    useEffect(() => {
        if (ref.current && ref.current.scrollIntoView) {
            ref.current.scrollIntoView();
        }
    }, [sessionId]);

    if (!session) {
        return <span>Session nicht gefunden :(</span>;
    }

    const otherSessions = sessions.filter((x) => x.id !== session.id && x.host.id === session.host.id);

    return (
        <>
            <div ref={ref} className="zww-session-detail">
                {session.images.teaser && <img className="zww-intro-image" src={session.images.teaser.view} alt="" />}

                <div className="zww-flex">
                    <div className="zww-session-detail-left">
                        <h3>{session.longTitle ?? session.title}</h3>

                        {session.cancelled && <h4 style={{ color: 'red' }}>Die Veranstaltung wurde abgesagt.</h4>}

                        <p>
                            <strong>Format des Events</strong>: {session.eventFormat}
                            {session.eventFormatSecondary && session.eventFormatSecondary !== session.eventFormat && (
                                <>, {session.eventFormatSecondary}</>
                            )}
                            {session.locationType !== undefined && locationType !== undefined && (
                                <>, {locationType[session.locationType]}</>
                            )}
                            <br />
                            {session.targetGroups !== undefined &&
                                session.targetGroups.length &&
                                targetGroup !== undefined && (
                                    <>
                                        <strong>Zielgruppe</strong>:{' '}
                                        {session.targetGroups.map((i) => targetGroup[i]).join(', ')}
                                        <br />
                                    </>
                                )}
                            <strong>Thema</strong>: {session.eventTopic}
                        </p>

                        {session.description?.long?.split(/\r?\n\r?\n/).map((ptext, i) => (
                            <p key={i}>
                                {ptext.split(/\r?\n/).map((line, i) => (
                                    <Fragment key={i}>
                                        {line}
                                        <br />
                                    </Fragment>
                                ))}
                            </p>
                        ))}

                        {session.links?.event ? (
                            <p>
                                <strong>Anmeldung &amp; weitere Infos</strong>: {eventUrl(session.links.event)}
                            </p>
                        ) : null}

                        <SdgIconList sdgs={session.sdgs} />
                    </div>

                    <div className="zww-session-detail-right">
                        <div className="date-details">
                            <h4>Termin</h4>
                            <strong>
                                {formatWeekday(session.start)}, {formatDate(session.start)}
                            </strong>
                            <br />
                            von: {formatTime(session.start)}
                            {session.end && <> bis: {formatTime(session.end)}</>}
                        </div>

                        <div className="event-details">
                            <h4>Details</h4>
                            {session.location ? (
                                <p>
                                    <strong>Ort</strong>
                                    <br />
                                    {session.location.name}
                                    <br />
                                    {session.location.streetNo}
                                    <br />
                                    {session.location.zipcode} {session.location.city}
                                </p>
                            ) : (
                                <p>Das Event findet ausschließlich online statt</p>
                            )}
                            <p>
                                <strong>Veranstalter:in</strong>
                                <br />
                                <Link to={`/veranstalter/${session.host.id}`}>{session.host.name}</Link>
                            </p>
                            <HostLinkBox links={session.host.links} />
                        </div>

                        <SessionMiniMap session={session} />
                    </div>
                </div>
            </div>
            <button className="zww-back-button" onClick={() => window.history.back()}>
                <i className="fas fa-arrow-left"></i> zurück
            </button>
            {otherSessions.length === 0 ? null : (
                <>
                    <h3 className="zww-sessions-of-host">Andere Veranstaltungen von {session.host.name}</h3>
                    <SessionList sessions={otherSessions} />
                </>
            )}
        </>
    );
};
